import Vue from 'vue'
import Vuei18n from 'vue-i18n'
import fr from './languages/fr.json'
import gb from './languages/gb.json'
import it from './languages/it.json'
import de from './languages/de.json'
import du from './languages/du.json'
import es from './languages/es.json'
import no from './languages/no.json'
import sw from './languages/sw.json'
import us from './languages/us.json'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
  locale: 'gb',
  fallbackLocale: 'gb',
  messages: {
    fr,
    gb,
    it,
    de,
    du,
    es,
    no,
    sw,
    us,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
})

export default i18n
